@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Inter';

}

@font-face {
  font-family: 'Satoshi';
  src: url(./assets/fonts/OTF/Satoshi-Variable.ttf);
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

/* override react-multi-select styles */
.rmsc {
  --rmsc-main: #3bb54b !important;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #e5e7eb !important;
  --rmsc-gray: hsl(0, 0%, 50%) !important;
  --rmsc-bg: #fff;
  --rmsc-p: 16px !important; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 50px !important; /* Height */
}

.success{
  border: 4px solid red;
}

